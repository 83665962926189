import React from "react"
import "../../styles/global.css"

import { useStaticQuery, graphql } from "gatsby"

import BackgroundImage from "gatsby-background-image"

export default function OurMissonBanner() {
  const data = useStaticQuery(graphql`
    {
      bgImage: allFile(
        filter: {
          relativeDirectory: { eq: "aboutPage" }
          extension: { eq: "png" }
          name: { eq: "our_mission" }
        }
      ) {
        edges {
          node {
            childrenImageSharp {
              fluid(maxWidth: 1280) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  var { bgImage } = data
  bgImage = bgImage.edges[0].node.childrenImageSharp[0]

  return (
    <div className="w-full flex justify-center items-center my-10">
      <div className="w-10/12 h-auto flex justify-center items-center">
        <BackgroundImage
          fluid={bgImage.fluid}
          style={{
            overflow: "hidden",
            borderRadius: "10px",
            backgroundSize: "cover",
            width: "100%",
          }}
          className="w-full aspect-auto flex justify-center items-center bg-cover"
        >
          <div className="flex lg:flex-row flex-col lg:justify-evenly justify-center items-center xl:p-20 p-10">
            <div className="lg:w-6/12 w-full flex justify-center items-center lg:p-0">
              <div className="w-full h-full rounded-tr-3xl rounded-bl-3xl rounded-tl-xl rounded-br-xl our-mission-gradient bg-opacity-60 drop-shadow-2xl backdrop-blur-xl flex flex-col justify-center items-center lg:px-20 px-3 lg:py-5 py-2">
                <div className="2xl:text-5xl xl:text-4xl md:text-2xl text-2xl  font-helvetica font-bold text-text-primary uppercase text-center mb-6">
                  <h1>our mission</h1>
                </div>
                <div className="w-full 2xl:text-xl md:text-sm text-sm font-thin font-helvetica text-text-primary text-center">
                  <p>
                    VSN Shared services mission is to provide you with the best
                    and most comprehensive services to ease your compliance
                    burden which will save around 70% of your administration
                    expenses, yet provides you with 100% quality.
                  </p>
                </div>
              </div>
            </div>
            <div className="w-2/4"></div>
          </div>
        </BackgroundImage>
      </div>
    </div>
  )
}
