const teamData = {
  founders: [
    {
      name: "vaibhav jain",
      designation: "Founder",
      intro:
        "A qualified Chartered Accountant and law graduate (LL.B) having an experience of more than a decade in the field of Accounting, Bookkeeping, Auditing, Internal Audit, Risk Advisory, Direct and Indirect Taxes.",
      extra:
        "A certified Quick book, Turbo Tax, and Xero accounting professional. Associated with ICAI (Institute of Chartered Accountants of India) as visiting faculty and has also authored a book on GST Titled “GST & Customs Law” published by Bloomsbury. Author of research paper on the Seamless flow of “Input Tax Credit” published by ICAI. Special Invitee to an echelon in the “Indirect Tax Committee” of ICAI. Imparted training to over 5,000 aspiring CA students during their GMCS course on time management and personality development.",
    },
    {
      name: "akhil jain",
      designation: "Founder",
      intro: "Mr. Akhil Jain enrolled as a member with ICAI IN 2011.",
      extra:
        "Over the period he has served some of the biggest names in Chartered Accountancy profession  including Ernst & Young, Dass Gupta & Associates where he has played central role in Audits of major corporate names in the industry. He has a rich  experience in Direct Taxation, Internal Audits & Statutory Audits. He also has wide experience of various ERP(s).",
    },
  ],
  strategicAdvisors: [
    {
      name: "k.k jain",
      designation: "Managing partner of Krishan Kumar Jain & Co.",
      intro:
        "Possesses a rich and vast experience in the field of litigation and Accounting services Auditing, and Assurance, Fund Management, VCFO, or part-time CFO services. In practice since 1987. Core specialization is in Accounting, Auditing, and Assurance, Direct and Indirect Taxation. In the past 30 years of his professional career, he has been appearing before various judicial and quasi-judicial authorities/forums in matters relating to Direct Taxes and Indirect Taxes which has helped him in getting useful insights into practical and legal nuances of judicial and quasi-judicial authorities/forums.",
      extra:
        "Appointed as a professional director in the Innovative Urban Cooperative Bank, New Delhi. Past Chairman of the Sonepat CP Study Circle of ICAI. Founding members of an NGO named CA Parivaar which helps the underprivileged CA Students and Newly qualified Chartered Account professionals in overall growth and development",
    },
    {
      name: "rakesh jain",
      designation:
        "Managing partner of Falcon International Consulting & Auditing",
      intro:
        "Managing partner at Falcon International Consulting & Auditing having its Head Office in Dubai and branches in Abu Dhabi & Sharjah is a well-reputed Financial & Business consultancy and Audit Firm in UAE duly approved by the Ministry of Economy, Government of United Arab Emirates, and by all major Banks.",
      extra:
        "FICA serves varied sectors in the areas of Audit Assurance, Business Risk Analysis, Local & Offshore Company Formation & Liquidation, Feasibility Studies & Project Financing, International Tax Planning, Assets Protection, Accounting Services and Business, Financial & Wealth Advisory services. FICA shall be your best choice when you look for Auditors, Accountants and Business, Financial & Wealth Advisory Services in Dubai, Abu Dhabi & all over United Arab Emirates. We aim financial success of our clients and committed to earn their trust and in return equip them with confidence in presenting their financial management to compliance authorities and other stake holders.",
    },
  ],
  consultants: [
    {
      name: "harsh gupta",
      designation: "Australia",
      intro:
        "A Certified Practicing Accountant, Australia, and a Certified IFRS professional. Have more than 10 years of professional experience across Australia and India. Core specialization lies in the field of Bookkeeping, Accounting, Tax, Treasury and Management Reporting.",
      extra:
        "Indian Chartered Accountant and an Alumni of Big 4 Accounting Firms in India hold an MBA degree from Macquarie Business School, Australia. Have supported various multinational corporations across different industries, including FMCG, automotive and automotive parts, wholesale cash and carry, space and satellite research, oil and gas etc. Appointed as a Special Invite to the Committee on International Taxation, Central Council, ICAI during the period 2018-2019 Author of various articles on emerging issues in International Taxation. Delivered multiple lectures on the subject Financial Management",
    },
    {
      name: "sakshi goel",
      designation: "Canada",
      intro:
        "Finance professional with more than six years of post-qualification experience in areas of Financial Planning & Analysis, Budgeting & Forecasting, Financial Modelling, Pricing/Project Coordination and Contract Negotiation. Goal-driven, self-motivated person and always in a beta mode of learning, improving and evolving in the field of Finance and Accounting.",
      extra: "",
    },
    {
      name: "aayush batra",
      designation: "Canada",
      intro:
        "Finance professional with over 8 years of experience specializing in areas of financial planning & analysis, finance business partnering, month-end reporting, budgeting, forecasting, variance analysis, accounting and business performance reviews & decision making. ",
      extra:
        "Annual & long-range planning, Monthly rolling forecasts, P&L & Budget management, Cost optimization projects, Driver based financial modeling, Cost of Revenue, Gross Margin & OPEX Analytics, Startup Experience 2+ years, Setup FP&A processes and controls, Process automation and improvements, Expertise in leadership/investor dashboards, Advanced MS Excel, PowerPoint & Word, Proficient in Hyperion, HFM, Congo’s, TM1, Hands-on knowledge of SAP & Oracle ERP.",
    },
  ],
  associates: [
    {
      name: "rahul chopra",
      designation: "Associate",
      intro:
        "He has a stellar record in the field of Audit and Assurance, Bank Audits, and Compliance Support.",
      extra:
        "he has been associated extensively in conducting Audits of various scheduled banks like BOI, State Bank of India, and State bank of Patiala. Besides Banks Audits, He  is mentoring the Audits in various sectors including Information Technology, Iron & Steel, Retail, Financial Services, Education, Real Estate, and others.",
    },
    {
      name: "vishal jain",
      designation: "Associate",
      intro:
        "He has a professional experience of more than 8 years and a stellar record in the field of Internal Audits and Tax Audits.	His technology-driven and problem-solving approach toward the client’s issues especially during internal audits has led to immense cost-saving and resource-saving for clients. He is well versed with all the latest technological accounting tools.",
      extra:
        " His forte has been remote bookkeeping and financial modeling. He has a first hands-on experience working on QuickBooks, Xero, Wave, Myob, Clearbooks, Zoho Books, Tally 9 ERP, Busy, and others.",
    },
    {
      name: "shikha jain",
      designation: "Associate",
      intro:
        "A qualified Chartered Accountant with a professional experience of over 8 years. Possess an experience in the field of system automation and compliance support in relation to Company Law and Foreign Exchange Regulations. Core specialization is in cost optimization, revenue growth, and margin/profit maximization across business processes.",
      extra:
        "A certified Quick book, Turbo Tax, and Xero accounting professional. Conducted the statutory Branch Audit for the State Bank of India for more than 11 branches. Awarded Spot Award for her excellent work and for showing great enthusiasm for every piece allocated at HCL. Proficiency in Excel and special techniques to save cost by simplifying the process which is being acclaimed by many of the clients. Her last assignment was with HSBC, wherein she was part of the Stress Testing Team as Assistant Manager for the USA region and worked on Comprehensive Capital Analysis and Review (CCAR), Dodd-Frank Stress Test (DFAST).",
    },
  ],
}

module.exports = { teamData: teamData }
