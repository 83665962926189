import React from "react"

import "../styles/global.css"

import Navbar from "../components/Navbar"
import MobileNavbar from "../components/MobileNavbar"
import Footer from "../components/Footer"
import ContactFooter from "../components/ContactFooter"
import AboutHeaderBanner from "../components/AboutPage/AboutHeaderBanner"
import OurMissionBanner from "../components/AboutPage/OurMissonBanner"
import Whatsapp from "../components/Whatsapp"
import TeamSection from "../components/AboutPage/TeamSection"

import Seo from "../components/seo"

export default function OurServicesPage() {
  return (
    <>
      <Seo title="ABOUT" />
      <Whatsapp />
      <div className="w-full flex flex-col justify-center items-center font-helvetica">
        <div className="md:hidden w-full flex flex-col justify-center items-center font-helvetica">
          <MobileNavbar>
            <AboutHeaderBanner />
            <OurMissionBanner />
            <TeamSection />
            <Footer />
            <ContactFooter />
          </MobileNavbar>
        </div>
        <div className="md:visible md:w-full md:flex md:flex-col md:justify-center md:items-center md:font-helvetica hidden">
          <Navbar />
          <AboutHeaderBanner />
          <OurMissionBanner />
          <TeamSection />
          <Footer />
          <ContactFooter />
        </div>
      </div>
    </>
  )
}
