import React, { useState } from "react"

import { useStaticQuery, graphql } from "gatsby"
import { teamData } from "./team"

import { GatsbyImage } from "gatsby-plugin-image"
import { getImage } from "gatsby-plugin-image"

const Popup = ({ data, setModalOpen, open, close }) => {
  return (
    <div>
      <div
        class={`modal py-5 overflow-y-auto modal-middle ${
          open ? "modal-open" : ""
        }`}
      >
        <div class="modal-box overflow-y-auto max-h-screen w-11/12 sm:w-full max-w-2xl flex flex-col justify-center items-center bg-white border-2 border-text-primary rounded-xl">
          <div className="w-full flex justify-end items-end">
            <div class="modal-action">
              <label
                className="cursor-pointer"
                onClick={() => setModalOpen("")}
              >
                <img src={close} alt="close" width={40} />
              </label>
            </div>
          </div>
          <div className="w-10/12 flex flex-col justify-center items-center modal-content modal-scrollable">
            <h1 className="text-center text-text-primary uppercase font-bold text-lg md:text-xl lg:my-5 my-2">
              introduction
            </h1>
            <p className="text-center text-text-primary text-[0.75rem] md:text-sm xl:text-lg">
              {data.intro}
            </p>
          </div>
          <div className="w-10/12 flex flex-col justify-center items-center lg:mb-20 mb-10">
            <h1 className="text-center text-text-primary uppercase font-bold text-lg md:text-xl lg:my-5 my-2">
              {data.extra !== "" ? "Professional achievements" : ""}
            </h1>
            <p className="text-center text-text-primary text-[0.75rem] md:text-sm xl:text-lg">
              {data.extra}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

const Member = ({ data, image, setModalOpen, idx, pad = false }) => {
  return (
    <>
      <div className="md:w-96 md:h-96 w-80 h-auto p-5 aspect-square flex flex-col justify-center items-center">
        <div
          onClick={() => setModalOpen(idx)}
          className={`gradient-member md:p-5 p-5 w-full aspect-square flex flex-col justify-evenly items-center rounded-xl shadow-black drop-shadow-2xl transition-all cursor-pointer  hover:bg-card-member-hover`}
        >
          <GatsbyImage
            image={getImage(image)}
            className="w-52 h-52 border-2 rounded-full border-text-primary aspect-square"
          />
          <h1 className="text-text-primary text-center font-bold uppercase text-2xl md:my-2 my-2">
            {data.name}
          </h1>
          <p className="text-text-primary text-center text-lg">
            {data.designation}
          </p>
        </div>
      </div>
    </>
  )
}

export default function TeamSection() {
  const data = useStaticQuery(graphql`
    {
      images: allFile(
        filter: {
          relativeDirectory: { eq: "aboutPage/team" }
          extension: { eq: "png" }
        }
      ) {
        edges {
          node {
            name
            childrenImageSharp {
              gatsbyImageData(aspectRatio: 1)
            }
          }
        }
      }
      close: allFile(
        filter: {
          relativeDirectory: { eq: "contactPage/popup" }
          name: { eq: "close" }
          extension: { eq: "svg" }
        }
      ) {
        edges {
          node {
            publicURL
            name
          }
        }
      }
    }
  `)

  var { images, close } = data
  const imagesEdges = images.edges
  close = close.edges[0].node.publicURL

  const memberKeys = [1, 2, 3, 4, 5, 6, 7]

  images = {}

  imagesEdges.map(
    image =>
      (images[image.node.name] =
        image.node.childrenImageSharp[0].gatsbyImageData)
  )

  const [modalOpen, setModalOpen] = useState("")
  return (
    <>
      <div className="w-full flex justify-center items-center" id="team">
        <div className="lg:w-10/12 w-full flex-col justify-center items-center p-5">
          <div className="w-full flex flex-col justify-center items-center my-10">
            <div className="2xl:text-4xl xl:text-3xl md:text-2xl text-xl   font-helvetica font-bold text-text-primary uppercase text-center my-10">
              <h1>meet our team</h1>
            </div>
            <div className="w-3/4 2xl:text-xl md:text-sm text-sm font-thin font-helvetica text-text-primary text-center">
              <p>
                Our methodical approach provides not only unparalleled services,
                we also provide creative answers and effective solutions to
                challenges faced by our clients.{" "}
              </p>
            </div>
          </div>
          <div className="w-full flex flex-col justify-center items-center lg:mt-20">
            <div className="2xl:text-3xl xl:text-2xl md:text-xl text-xl   font-helvetica font-bold text-text-primary uppercase text-center lg:mt-10">
              <h1>our founders</h1>
            </div>
            <div className="w-full flex lg:flex-row flex-col justify-center items-center my-5">
              <Member
                data={teamData.founders[0]}
                image={images[teamData.founders[0].name.replace(" ", "_")]}
                idx="f-1"
                setModalOpen={setModalOpen}
              />
              <Member
                data={teamData.founders[1]}
                image={images[teamData.founders[1].name.replace(" ", "_")]}
                idx="f-2"
                setModalOpen={setModalOpen}
              />
            </div>
          </div>
          <div className="w-full flex flex-col justify-center items-center lg:mt-20">
            <div className="2xl:text-3xl xl:text-2xl md:text-xl text-xl   font-helvetica font-bold text-text-primary uppercase text-center">
              <h1>OUR TEAM OF PROFESSIONALS</h1>
            </div>
            <div className="2xl:text-3xl xl:text-2xl md:text-xl text-xl   font-helvetica font-bold text-text-primary uppercase text-center mt-20">
              <h1>key strategic advisors</h1>
            </div>
            <div className="w-full flex lg:flex-row flex-col justify-center items-center my-5">
              <Member
                data={teamData.strategicAdvisors[0]}
                image={
                  images[teamData.strategicAdvisors[0].name.replace(" ", "_")]
                }
                idx="st-1"
                setModalOpen={setModalOpen}
              />
              <Member
                data={teamData.strategicAdvisors[1]}
                image={
                  images[teamData.strategicAdvisors[1].name.replace(" ", "_")]
                }
                idx="st-2"
                setModalOpen={setModalOpen}
              />
            </div>
          </div>

          <div className="w-full flex flex-col justify-center items-center lg:mt-20">
            <div className="2xl:text-3xl xl:text-2xl md:text-xl text-xl   font-helvetica font-bold text-text-primary uppercase text-center mt-10">
              <h1>our associates</h1>
            </div>
            <div className="w-full flex lg:flex-row flex-col justify-center items-center my-5">
              <Member
                data={teamData.associates[0]}
                image={images[teamData.associates[0].name.replace(" ", "_")]}
                idx="as-1"
                setModalOpen={setModalOpen}
              />
              <Member
                data={teamData.associates[1]}
                image={images[teamData.associates[1].name.replace(" ", "_")]}
                idx="as-2"
                setModalOpen={setModalOpen}
              />

              <Member
                data={teamData.associates[2]}
                image={images[teamData.associates[2].name.replace(" ", "_")]}
                idx="as-3"
                setModalOpen={setModalOpen}
              />
            </div>
          </div>

          <div className="w-full flex flex-col justify-center items-center lg:mt-20">
            <div className="2xl:text-3xl xl:text-2xl md:text-xl text-xl   font-helvetica font-bold text-text-primary uppercase text-center mt-10">
              <h1>our foreign consultants</h1>
            </div>
            <div className="w-full flex lg:flex-row flex-col justify-center items-center my-5">
              <Member
                data={teamData.consultants[0]}
                image={images[teamData.consultants[0].name.replace(" ", "_")]}
                idx="cs-1"
                setModalOpen={setModalOpen}
              />
              <Member
                data={teamData.consultants[1]}
                image={images[teamData.consultants[1].name.replace(" ", "_")]}
                idx="cs-2"
                setModalOpen={setModalOpen}
              />

              <Member
                data={teamData.consultants[2]}
                image={images[teamData.consultants[2].name.replace(" ", "_")]}
                idx="cs-3"
                setModalOpen={setModalOpen}
              />
            </div>
          </div>
        </div>
      </div>

      {teamData.founders.map((node, idx) => (
        <Popup
          data={teamData.founders[idx]}
          open={modalOpen == `f-${idx + 1}`}
          setModalOpen={setModalOpen}
          close={close}
        />
      ))}

      {teamData.strategicAdvisors.map((node, idx) => (
        <Popup
          data={teamData.strategicAdvisors[idx]}
          open={modalOpen == `st-${idx + 1}`}
          setModalOpen={setModalOpen}
          close={close}
        />
      ))}

      {teamData.consultants.map((node, idx) => (
        <Popup
          data={teamData.consultants[idx]}
          open={modalOpen == `cs-${idx + 1}`}
          setModalOpen={setModalOpen}
          close={close}
        />
      ))}

      {teamData.associates.map((node, idx) => (
        <Popup
          data={teamData.associates[idx]}
          open={modalOpen == `as-${idx + 1}`}
          setModalOpen={setModalOpen}
          close={close}
        />
      ))}
    </>
  )
}
